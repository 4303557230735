(function ($) {
    function revealElement(element) {
        var elementTop = element.offset().top + 100;
        var elementBottom = elementTop + element.outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();        

        if (elementBottom > viewportTop && elementTop < viewportBottom ) {            
            if( !element.hasClass('revealed') ) {
                element.addClass('revealed');
                startCounting(element);
            }
        }
    }

    function countUpNumber(element, targetNumber, duration = 3000) {
        var startNumber = 0;
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var stepTime = 10; // Milliseconds per step (adjust as needed)
  
        function updateNumber() {
          var now = new Date().getTime();
          var remaining = Math.max((endTime - now) / duration, 0);
          var currentNumber = Math.round(targetNumber - (remaining * (targetNumber - startNumber)));
          $(element).text(currentNumber);
  
          if (currentNumber < targetNumber) {
            setTimeout(updateNumber, stepTime);
          }
        }
  
        updateNumber();
    }

    function startCounting(element) {
        if ( $('.animation-item.count-up').length > 0 ) {
            target = element.find('.number');
            target.each(function(index) {
                var targetNumber = $(this).data("target-number");                
                countUpNumber(this, targetNumber);
            });
        }
    }

    $('.animation-section').each(function(){
        $(this).find('.animation-item').each(function(i){            
            fadeDelay = i*200;
            $(this).css("transition-delay", fadeDelay + "ms");
        });        
    });

    $(window).on('load', function() {
        $('.animation-section').each(function() {
            revealElement($(this));
        });
    });

    $(window).on('scroll', function() {
        $('.animation-section').each(function() {
            revealElement($(this));
        });
    });
})(jQuery);