import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.partner').length > 0 ) {
		var partnerCarousel = {
			init: function(){
				$('.partner').each(function(){
                    const slider = $(this).find('.partner__item-wrapper');
                    const settings = {
                        infinite: true,
                        dots: true,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 1079,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                            {
                                breakpoint: 1279,
                                settings: "unslick"
                            }

                        ]
                    };

                    const sl =  slider.not('.slick-initialized').slick(settings);

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( !sl.hasClass('slick-initialized')) {
							partnerCarousel.init();
						  }	
						}, 500, 'partner-carousel');
					});
			 	});
				
			}
		};

		partnerCarousel.init();

	}
})(jQuery);