import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.internal-banner-slider').length > 0 ) {
		var internalBanner = {
			init: function(){
				$('.internal-banner-slider').each(function(){
                    const slider = $(this);                    
                    // const arrowWrapper = $(this).find('.projects-carousel__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: true,
                        speed: 3000,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 1,
                        slidesToScroll: 1,                        
                        // appendArrows : arrowWrapper,
                    };

                    slider.not('.slick-initialized').slick(settings);
			 	});
				
			}
		};

		$(function() {
			internalBanner.init();
		});

        $(window).on("resize orientationchange", function () {
            waitForFinalEvent(function(){
                internalBanner.init();
            }, 500, 'internal-banner');
        });	

	}
})(jQuery);