import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
    var makeSameHeight = {
        init: function(){
            if ( $('.content-card__wrapper').length > 0 ) {
                $('.content-card__wrapper').each(function(){
                    $(this).find('.content-card__title').matchHeight();
                }); 
            }
            if ( $('.news-box__content-wrapper ').length > 0 ) {
                $('.news-box__content-wrapper ').each(function(){
                    $(this).find('.content-card__title').matchHeight();
                }); 
            }
            if ( $('.two-column-links').length > 0 ) {
                $('.two-column-links').each(function(){
                    $(this).find('.two-column-links__item-inner-wrapper').matchHeight({
                        property: 'min-height',
                    });
                }); 
            }            
        }
    };

    $(function() {
        waitForFinalEvent(function(){
            makeSameHeight.init();
        }, 500, 'same-height-init');
    });

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            makeSameHeight.init();
        }, 500, 'same-height');
    });

})(jQuery);