import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.box-carousel:not(.no-carousel)').length > 0 ) {
		var boxCarousel = {
			init: function(){
				$('.box-carousel:not(.no-carousel)').each(function(){
                    const slider = $(this).find('.box-carousel__box-wrapper');
                    const nextArrow = $(this).find('.box-carousel__arrow.next');
                    const prevArrow = $(this).find('.box-carousel__arrow.prev');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        prevArrow: prevArrow,
                        nextArrow: nextArrow,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 1079,
                                settings: {
                                    slidesToShow: 4,
                                }
                            }

                        ]
                    };

                    // if ($(this).children().length > 2) {

                    const sl =  slider.not('.slick-initialized').slick(settings);

                    // }

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( !sl.hasClass('slick-initialized')) {
							boxCarousel.init();
						  }	
						}, 500, 'box-carousel');
					});
			 	});
				
			}
		};

		boxCarousel.init();

	}
})(jQuery);