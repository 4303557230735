export const sortTable = (columnIndex) => {
    const table = document.getElementById('member-table');
    const tbody = table.tBodies[0];
    const rows = Array.from(tbody.rows);
    const th = table.querySelectorAll('th');

    let isAscending = true;
    if (th[columnIndex].classList.contains('sort-asc')) {
        isAscending = false;
    }

    th.forEach(header => header.classList.remove('sort-asc', 'sort-desc'));
    th[columnIndex].classList.toggle('sort-asc', isAscending);
    th[columnIndex].classList.toggle('sort-desc', !isAscending);

    rows.sort((a, b) => {
        const cellA = a.cells[columnIndex].innerText;
        const cellB = b.cells[columnIndex].innerText;

        if (isNumeric(cellA) && isNumeric(cellB)) {
            return isAscending ? cellA - cellB : cellB - cellA;
        } else {
            return isAscending ? cellA.localeCompare(cellB) : cellB.localeCompare(cellA);
        }
    });

    rows.forEach(row => tbody.appendChild(row));
};

const isNumeric = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
};
