jQuery(document).ready(function ($) {
	$('.menu-toggle').on('click', function () {
		$('.mobile-menu__wrapper').addClass('active');
	});

	$('.mobile-menu__close-button, .mobile-menu__outer').on('click', function () {
		$('.mobile-menu__wrapper').removeClass('active');
		$('.mobile-menu').removeClass('child-active');
		$('.mobile-menu').next('.mobile-menu__cta-wrapper').removeClass('child-active');
		$('.mobile-menu').find('.sub-menu').hide();
	});

	$('#mobile-menu')
	.find('.menu-item-has-children')
	.each(function () {
		//copy the parent menu to the bottom sub-menu to make a view all link.
		let parentMenuOriginal = $(this).children('a').clone().html('View All');
		let parentMenu = $("<div />").append( parentMenuOriginal ).html();
		$(this).find('.sub-menu').append('<li class="mobile-menu__view-all">'+parentMenu+'</li>');

		//create a back button on the sub-menu
		$(this).find('.sub-menu').prepend('<li class="mobile-menu__back"><a href="#">back</a></li>');

		//action on the menu item click
		$(this)
		.children('a')
		.on('click', function (e) {
			e.preventDefault();
			$(this).parents('.mobile-menu').addClass('child-active');
			$(this).parents('.mobile-menu').next('.mobile-menu__cta-wrapper').addClass('child-active');	
			$(this).next('.sub-menu').show();
		});
	});

	$(document).on('click', '.mobile-menu__back', function () {
		$(this).parents('.sub-menu').hide();
		$(this).parents('.mobile-menu').removeClass('child-active');
		$(this).parents('.mobile-menu').next('.mobile-menu__cta-wrapper').removeClass('child-active');
	});
});
