import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.event-boxes.carousel').length > 0 ) {
		var eventCarousel = {
			init: function(){
				$('.event-boxes.carousel').each(function(){
                    const slider = $(this).find('.event-boxes__item-wrapper.carousel');
                    const nextArrow = $(this).find('.event-boxes__arrow.next');
                    const prevArrow = $(this).find('.event-boxes__arrow.prev');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 2,
                        slidesToScroll: 1,                        
                        mobileFirst: true,
                        prevArrow: prevArrow,
                        nextArrow: nextArrow,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 1079,
                                settings: {
                                    slidesToShow: 4,
                                }
                            }

                        ]
                    };

                    const sl =  slider.not('.slick-initialized').slick(settings);

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( !sl.hasClass('slick-initialized')) {
							eventCarousel.init();
						  }	
						}, 500, 'event-carousel');
					});
			 	});
				
			}
		};

		eventCarousel.init();

	}
})(jQuery);